@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
.carousel {
  position: relative;
}
.carousel .carousel--inner {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel--item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}
.carousel--item.active {
  display: block;
}

.ng-select.dropdown-filter .ng-select-container {
  max-height: 36px;
  min-width: 80px;
  border: 0px;
}
.ng-select.dropdown-filter .ng-select-container .ng-input {
  display: none;
}
.ng-select.dropdown-filter .ng-select-container .ng-value-container {
  padding-left: 8px;
}
.ng-select.dropdown-filter .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
}
.ng-select.dropdown-filter .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: #3c70d5;
  font-size: 12px;
  line-height: 14px;
}
.ng-select.dropdown-filter .ng-select-container .ng-value-container .ng-placeholder {
  line-height: 14px;
  font-size: 12px;
}

.dropdown-filter.ng-dropdown-panel,
.multiselect-filter.ng-dropdown-panel {
  padding: 8px 0px;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom {
  min-width: 220px;
  max-width: 330px;
  width: auto !important;
  opacity: 1;
  margin-top: 4px;
  border-radius: 4px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-arrow-wrapper,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-arrow-wrapper {
  padding-left: 4px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .select-group-prepend,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .select-group-prepend {
  display: flex;
  align-items: center;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .select-group-prepend input,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .select-group-prepend input {
  width: 80%;
  height: 100%;
  border: 0px;
  color: #b7bbc0;
  font-size: 12px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .select-group-text,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .select-group-text {
  box-sizing: border-box;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .select-group-text i,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .select-group-text i {
  color: #b7bbc0;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-header,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-header {
  border: 0px;
  padding: 0px;
}
@media only screen and (max-width: 1400px) {
  .dropdown-filter.ng-dropdown-panel.ng-select-bottom,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom {
    max-width: 300px;
  }
}
@media only screen and (max-width: 1300px) {
  .dropdown-filter.ng-dropdown-panel.ng-select-bottom,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom {
    max-width: 250px;
  }
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-option,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-option {
  height: 36px;
  padding: 8px 16px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-option .ng-option-label,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-option .ng-option-label {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
  min-height: 20px;
  letter-spacing: 0px;
  line-height: 14px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-option.ng-option-marked,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-option.ng-option-marked {
  background-color: #e5f1fc;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option {
  height: 36px;
  white-space: pre-wrap;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 14px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option.ng-option-marked,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f2f2f2;
}
.dropdown-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option.ng-option-disabled,
.multiselect-filter.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #7d7d7d !important;
  font-size: 12px;
}
.dropdown-filter.ng-dropdown-panel.ng-select-top,
.multiselect-filter.ng-dropdown-panel.ng-select-top {
  margin-bottom: 4px;
}
.dropdown-filter .multiselect-filter .input-group,
.multiselect-filter .multiselect-filter .input-group {
  border: 1px solid #e5e8ec;
  border-radius: 4px;
  margin-top: 8px;
  border: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
}
.dropdown-filter .multiselect-filter .input-group .input-group-text,
.dropdown-filter .multiselect-filter .input-group .form-control,
.multiselect-filter .multiselect-filter .input-group .input-group-text,
.multiselect-filter .multiselect-filter .input-group .form-control {
  opacity: 0.8;
  border-radius: 4px;
  background-color: white;
  border: none;
  color: #7d7d7d;
  font-size: 13px;
  height: initial;
}
@media only screen and (max-width: 1300px) {
  .dropdown-filter .multiselect-filter .input-group,
.multiselect-filter .multiselect-filter .input-group {
    max-width: 250px;
  }
}
.dropdown-filter .multiselect-filter .input-group .ng-option,
.multiselect-filter .multiselect-filter .input-group .ng-option {
  border-bottom: #e7e7e7 1px solid;
  height: 36px;
}
.dropdown-filter .multiselect-filter .input-group .ng-option .ng-option-label,
.multiselect-filter .multiselect-filter .input-group .ng-option .ng-option-label {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: normal;
  min-height: 20px;
  letter-spacing: 0px;
  line-height: 14px;
}
.dropdown-filter .multiselect-filter .input-group .ng-dropdown-panel-items .ng-option,
.multiselect-filter .multiselect-filter .input-group .ng-dropdown-panel-items .ng-option {
  height: 36px;
  white-space: pre-wrap;
  box-shadow: inset 0 -1px 0 0 #e5e8ec;
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 14px;
}

.multiselect-filter .input-group .input-group-text,
.multiselect-filter .input-group .form-control {
  opacity: 0.8;
  border-radius: 4px;
  background-color: white;
  border: none;
  color: #7c8495;
  font-size: 13px;
  height: initial;
}
.multiselect-filter .input-group .input-group-prepend {
  height: initial;
}

.ng-select.multiselect-filter .ng-select-container {
  max-height: 36px;
  min-width: 80px;
  border: 0px;
}
.ng-select.multiselect-filter .ng-select-container .ng-input {
  display: none;
}
.ng-select.multiselect-filter .ng-select-container .ng-value-container {
  padding-left: 8px;
  padding-top: 0px;
}
.ng-select.multiselect-filter .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
}
.ng-select.multiselect-filter .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: #3c70d5;
  font-size: 12px;
  line-height: 14px;
}
.ng-select.multiselect-filter .ng-select-container .ng-value-container .ng-placeholder {
  line-height: 24px;
  font-size: 12px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dropdown-filter .ng-arrow-wrapper,
.multiselect-filter .ng-arrow-wrapper {
    display: inline-block;
    line-height: 36px;
  }
}