.carousel {
    position: relative;
    .carousel--inner {
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
}

.carousel--item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    perspective: 1000px;
    &.active {
        display: block;
    }
}